/* Base styles */
:root {
    --font-primary: 'Satoshi', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body,
.MuiTypography-root,
.MuiButton-root,
.MuiInputBase-root {
    font-family: var(--font-primary) !important;
}

.landing-page {
    font-family: var(--font-primary);
    padding: 20px;
    text-align: center;
}

.use-case-card {
    font-family: var(--font-primary);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Remove the general hover effect */
/* .use-case-card:hover {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
} */

/* Add specific hover effects for each card */
.use-case-card[data-expanded="true"] {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.glow-button {
    font-family: var(--font-primary);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.glow-button:hover {
    box-shadow: 0 0 15px 5px rgba(33, 150, 243, 0.7);
    transform: scale(1.1);
}

@media (max-width: 600px) {
    .use-case-card[data-expanded="true"] {
        transform: none;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .glow-button:hover {
        transform: scale(1.05);
    }
}

.logo {
    width: 100px;
    height: auto;
}

.hero-gradient {
    background: linear-gradient(135deg, #0041C2 0%, #00B4DB 100%);
}

.section-padding {
    padding: 80px 0;
}

@media (max-width: 600px) {
    .section-padding {
        padding: 40px 0;
    }
}

.feature-card {
    font-family: var(--font-primary);
    transition: transform 0.3s ease;
}

.feature-card:hover {
    transform: translateY(-5px);
}

/* Typography classes */
.heading-large {
    font-family: var(--font-primary);
    font-weight: 800;
    letter-spacing: -0.03em;
}

.heading-medium {
    font-family: var(--font-primary);
    font-weight: 700;
    letter-spacing: -0.02em;
}

.body-text {
    font-family: var(--font-primary);
    font-weight: 400;
    letter-spacing: 0.01em;
}

.button-text {
    font-family: var(--font-primary);
    font-weight: 600;
    letter-spacing: 0.02em;
}

@keyframes moveBackground {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}