@font-face {
    font-family: 'Satoshi';
    src: url('./assets/fonts/Satoshi-Regular.woff2') format('woff2'),
        url('./assets/fonts/Satoshi-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Satoshi';
    src: url('./assets/fonts/Satoshi-Bold.woff2') format('woff2'),
        url('./assets/fonts/Satoshi-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Satoshi';
    src: url('./assets/fonts/Satoshi-Medium.woff2') format('woff2'),
        url('./assets/fonts/Satoshi-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

/* Base styles */
body {
    font-family: 'Satoshi';
}